import React from "react";
import { Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-5">
        <Container>
          <Row className="align-items-center justify-content-xl-between">
            <Col xl="12" className="pt-4">
              <div className="copyright text-center text-muted">
                © Copyright 2019 - {new Date().getFullYear()}{" "}
                EasyMonk - All Rights Reserved
              </div>
            </Col>

          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
