// import jwt_decode from "jwt-decode";
import { PostData } from "../../services/PostData";
import DateFormat from "./DateFormat";
import TimestampDateFormat from './DateFromTimestamp';
import axios from "axios";

import moment from 'moment-timezone';
moment.tz.setDefault("America/phoenix");

// let CryptoJS = require("crypto-js");

const phpUrl = process.env.REACT_APP_PHP_ENDPOINT;

export const checkColorCondition = (row) => {
    const userData = loggedInUser();
    let classNameForRow = '';

    // if( (process.env.NODE_ENV === "development" && row.company_id === 25) || (process.env.NODE_ENV === "production" && row.company_id === 10) )
    if (userData.report_order_company_id === userData.company_id) {
        let currentDate = new Date();
        currentDate = `${currentDate.getMonth() + 1}/${currentDate.getDate()}/${currentDate.getFullYear()}`;
        currentDate = new Date(currentDate);

        let dueDate = new Date(row.current_due_date);
        const day = dueDate.getDay();

        dueDate = `${dueDate.getMonth() + 1}/${dueDate.getDate()}/${dueDate.getFullYear()}`;
        dueDate = new Date(dueDate);

        if (day === 5) {
            dueDate.setDate(dueDate.getDate() + 3);
        } else if (day === 6) {
            dueDate.setDate(dueDate.getDate() + 2);
        } else {
            dueDate.setDate(dueDate.getDate() + 1);
        }

        if (dueDate < currentDate && row.is_rush_order < 3) {
            classNameForRow = 'custom-due-rush';
        }
        else if (dueDate < currentDate) {
            classNameForRow = 'custom-due-date';
        } else if (row.is_rush_order < 3) {
            classNameForRow = 'custom-rush-order';
        }
    } else {
        if (row.is_rush_order < 3) {
            classNameForRow = 'custom-rush-order';
        }
    }
    return classNameForRow;
};

export const AutoLogin = (data, callback) => {

    PostData("auth/encrypt", { id: data.user_id }).then(result => {
        let responseJson = result.data;
        if (responseJson.status === 200) {
            window.open(phpUrl + "login?token=" + responseJson.data.string + "&url=" + data.url + "&id=" + data.user_id, "_blank");
            callback(true);
            return true;
        }

    }).catch(errors => {
        return false;
    });
};

export const checkRoles = function (roleArray) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleNames : [];
    let is_exist = false;
    if (loggedInUserRoles) {

        loggedInUserRoles.forEach((currentRole, key) => {
            loggedInUserRoles[key] = currentRole.replace(' ', '_').toUpperCase();
        });
        roleArray.forEach(role => {
            role = role.replace(' ', '_');

            let index = loggedInUserRoles.findIndex(item => role.toUpperCase() === item.toUpperCase());

            if (index > -1) {
                is_exist = true;
            }
        });
    }


    return is_exist;
};

export const checkRolesWithLevel = function (rolesArrayToCheck, operatorToMatch, levelToCheck) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleData : [];

    loggedInUserRoles.forEach((currentRole, key) => {
        loggedInUserRoles[key].role_name = currentRole.role_name.replace(' ', '_').toUpperCase();
    });

    rolesArrayToCheck.forEach((role, key) => {
        rolesArrayToCheck[key] = role.replace(' ', '_').toUpperCase();
    });

    let is_exist = false;
    loggedInUserRoles.forEach(role => {
        let index = rolesArrayToCheck.findIndex(item => role.role_name === item);

        if (operatorToMatch && levelToCheck) {

            role.user_role.level = Number(role.user_role.level);
            levelToCheck = Number(levelToCheck);

            if (operatorToMatch === '=' || operatorToMatch === '==' || operatorToMatch === '===') {
                if (index > -1 && role.user_role.level === levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '>') {
                if (index > -1 && role.user_role.level > levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '>=') {
                if (index > -1 && role.user_role.level >= levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '<') {
                if (index > -1 && role.user_role.level < levelToCheck) {
                    is_exist = true;
                }
            } else if (operatorToMatch === '<=') {
                if (index > -1 && role.user_role.level <= levelToCheck) {
                    is_exist = true;
                }
            }
        } else {
            if (index > -1) {
                is_exist = true;
            }
        }
    });

    return is_exist;
};

export const checkRolesWithEval = function (rolesArrayToCheck, operatorToMatch, signatureToCheck) {
    const userData = loggedInUser();
    let loggedInUserRoles = userData ? userData.roleData : [];

    loggedInUserRoles.forEach((currentRole, key) => {
        loggedInUserRoles[key].role_name = currentRole.role_name.replace(' ', '_').toUpperCase();
    });

    rolesArrayToCheck.forEach((role, key) => {
        rolesArrayToCheck[key] = role.replace(' ', '_').toUpperCase();
    });

    let is_exist = false;
    loggedInUserRoles.forEach(role => {
        let index = rolesArrayToCheck.findIndex(item => role.role_name === item);

        if (operatorToMatch && signatureToCheck) {
            role.user_role.signature = Number(role.user_role.signature);
            signatureToCheck = Number(signatureToCheck);

            if (operatorToMatch === '=' || operatorToMatch === '==' || operatorToMatch === '===') {
                if (index > -1 && role.user_role.signature === signatureToCheck) {
                    is_exist = true;
                }
            }
        } else {
            if (index > -1) {
                is_exist = false;
            }
        }
    });

    return is_exist;
};

export const loggedInUser = function () {
    let userData = {};
    // return localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
    if (localStorage.getItem("userData")) {
        let userToken = JSON.parse(localStorage.getItem("userData"));
        // let userData = jwt_decode(userToken.token);
        userData.token = userToken.token;
        let userProfile = JSON.parse(localStorage.getItem("userProfile"));
        userData.first_name = userProfile.first_name;
        userData.username = userProfile.cc_phone;
        return userData;
    } else {
        return {};
    }
};

// export const decodeJwt = function (string) {
//     // return localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : {};
//     if (string) {
//         let data = jwt_decode(string);
//         return data;
//     } else {
//         return {};
//     }
// };

export const truncate = function (str, maximumLen) {
    return str && str.length > maximumLen ? str.substring(0, maximumLen) + "..." : str;
};

export const formatNumber = function (amount, decimal = 2) {
    return amount && parseFloat(amount).toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const priceNumber = function (amount, decimal = 2) {
    if (decimal > 0) {
        return amount ? "$" + parseFloat(amount).toFixed(decimal).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "$" + parseFloat(0).toFixed(decimal);
    } else {
        return amount ? "$" + number_format(amount, 0) : "$" + number_format(0, 0);
    }
};

export const removePriceFormat = function (amount) {
    if (amount) {
        amount = amount.toString();
        return amount.includes("$") ? Number(amount.replace(/[^0-9]+/g, "")) : amount;
    } else {
        return 0;
    }
};

export const contactFormat = function (contact) {
    if (contact) {
        if (contact.includes('(') || contact.includes('-')) {
            return contact;
        } else {
            contact = contact.slice(-10).match(/(\d{3})(\d{3})(\d{4})/);
            return `(${contact[1]}) ${contact[2]}-${contact[3]}`;
        }
    }
}

export const getBase64 = function (file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export const objectToQueryParams = function (obj) {
    let paramsData = [];

    for (var p in obj)
        if (obj.hasOwnProperty(p)) {
            paramsData.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
        }
    return paramsData.join("&");
}

/*
| Number format, Identical to php number_format function
*/
export const number_format = function (number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec).replace(/\.0+$/, '');
};

/*
| Capital first character
*/
export const capitalizeFirstLetter = function (string) {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
};

export const moneyFormat = function (amount) {
    return parseFloat(amount).toFixed(0).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
};

/*
| Date formatter
*/
export const formatDate = function (row, dateVal, dateFormat) {

    if (dateVal && dateVal !== '-') {
        return moment(dateVal).format(dateFormat)
        // return (<DateFormat date={dateVal} format={dateFormat} />);
    }
    return "-";
};

export const formatDateComponent = function (fieldName, row, format, customToolTip, hoverFormat) {
    return (
        <>
            <DateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
        </>
    );
};

export const formatTimestampDateComponent = function (fieldName, row, format, customToolTip, hoverFormat) {
    return (
        <>
            <TimestampDateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
        </>
    );
};

export const formatPropertyImagesArray = function (propertyImgs) {
    if (propertyImgs) {
        propertyImgs = propertyImgs.replace("[", "");
        propertyImgs = propertyImgs.replace("]", "");
        let isQoute = propertyImgs.indexOf('"');

        if (isQoute !== -1) {
            propertyImgs = JSON.parse("[" + propertyImgs + "]");
        } else {
            propertyImgs = propertyImgs.split(",");
        }
        propertyImgs = propertyImgs.filter(element => element !== "\"\"" && element !== "");

        return propertyImgs;
    }

    return [];
};

// export const encryptData = function (data) {
//     if (data) {
//         return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPT_KEY).toString();

//     }
//     return "";
// };

// export const decryptData = function (string) {
//     if (string) {
//         var bytes = CryptoJS.AES.decrypt(string, process.env.REACT_APP_ENCRYPT_KEY);
//         var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
//         return decryptedData;
//     }
//     return "";
// }

/*
| Convert Byte to Mb
*/
export const formatBytes = function (bytes) {
    var marker = 1024;
    var decimal = 2;
    var kiloBytes = marker;
    var megaBytes = marker * marker;
    var gigaBytes = marker * marker * marker;

    // return bytes if less than a KB
    if (bytes < kiloBytes) return bytes + " Bytes";
    // return KB if less than a MB
    else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
    // return MB if less than a GB
    else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";

};

export const handleFlags = function (flags) {
    if (flags) {
        return (
            <>
                {flags.golf ? (
                    <img src="/images/Golf.png" alt="golf" width={20} />
                ) : (null)}

                {flags.water ? (
                    <img src="/images/Water.png" alt="water" width={20} />
                ) : (null)}
            </>
        )
    }
}

export const isNumeric = function (value) {
    return /^-?\d+$/.test(value);
}

export const checkValidPhone = function (phone) {
    let regex = /^\+(?:[0-9] ?){6,14}[0-9]$/;
    return regex.test(phone);
}

export function checkValueInArrayObjBykey(data, key) {
    let isThere = false;
    if (data.length > 0) {
        data.forEach(element => {
            if (element[key] === "") {
                isThere = true;
            }
        });
    }
    return isThere;
}

export const requestedMissingDocsFormat = function (docsArray) {
    let requested_missing_docs_arr = docsArray;

    let requested_missing_docs_display_val_arr = [];

    if (requested_missing_docs_arr.includes("plans")) {
        requested_missing_docs_display_val_arr.push("Plans");
    }
    if (requested_missing_docs_arr.includes("budget")) {
        requested_missing_docs_display_val_arr.push("Budget");
    }
    if (requested_missing_docs_arr.includes("property_info")) {
        requested_missing_docs_display_val_arr.push("Property Info");
    }
    if (requested_missing_docs_arr.includes("photo")) {
        requested_missing_docs_display_val_arr.push("Photos");
    }
    if (requested_missing_docs_arr.includes("inspection")) {
        requested_missing_docs_display_val_arr.push("Inspection");
    }
    if (requested_missing_docs_arr.includes("zoning_info")) {
        requested_missing_docs_display_val_arr.push("Zoning Info");
    }
    if (requested_missing_docs_arr.includes("property_type_confirmation")) {
        requested_missing_docs_display_val_arr.push("Property Type Confirmation");
    }
    if (requested_missing_docs_arr.includes("subject_property_mapping_issue")) {
        requested_missing_docs_display_val_arr.push("Subject Property Mapping Issue");
    }
    if (requested_missing_docs_arr.includes("processing_or_calculation_issue")) {
        requested_missing_docs_display_val_arr.push("Processing Or Calculation Issue");
    }
    if (requested_missing_docs_arr.includes("client_request")) {
        requested_missing_docs_display_val_arr.push("Client Request");
    }

    let requested_docs_formatted = requested_missing_docs_display_val_arr.length ? requested_missing_docs_display_val_arr.toString(", ") : "-";

    return requested_docs_formatted;
};

export const currencyFormatToNumber = function (value) {

    value = value.toString();
    value = value.replace("$", "");
    value = value.replace(",", "");

    return value;
};

export const isNotEmpty = function (value) {
    if (value !== "" && value !== null && value !== "null" && value !== undefined) {
        return value
    }
    return false;

};
export const sendExceptionEmail = async function (error) {
    console.log("In sendExceptionEmail function", error)

    if (!error.api_error) {
        console.log("Need to send an email");
    }
}

export const generateRandomString = function (length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const secondsToHHMMSS = function (seconds) {
    const duration = moment.duration(seconds, 'seconds');
    return moment.utc(duration.asMilliseconds()).format('HH:mm:ss');
};

// export const getConditionRatingColor = function (currentRating) {
//     let color = null;
//     let actualCondition = null;
//     let imageUrl = null;
//     let imageSrc = null;
//     let baseUrl = process.env.REACT_APP_BASE_URL;
//     if (currentRating >= CONDITION.UNSALVAGE_MIN && currentRating <= CONDITION.UNSALVAGE_MAX) {
//         color = '#575757';
//         actualCondition = 1;
//         imageSrc = require("../../assets/img/conditions/unsalvageable-grey.png").default;
//         imageUrl = `${baseUrl}assets/img/conditions/unsalvageable-grey.png`
//     } else if (currentRating >= CONDITION.VERY_POOR_MIN && currentRating <= CONDITION.VERY_POOR_MAX) {
//         color = '#A63322';
//         actualCondition = 2;
//         imageSrc = require("../../assets/img/conditions/very-poor.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Very Poor" />`
//     } else if (currentRating >= CONDITION.POOR_MIN && currentRating <= CONDITION.POOR_MAX) {
//         color = '#F4AB2C';
//         actualCondition = 3;
//         imageSrc = require("../../assets/img/conditions/poor.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Poor" />`
//     } else if (currentRating >= CONDITION.MODERATE_MIN && currentRating <= CONDITION.MODERATE_MAX) {
//         color = '#E6E85E';
//         actualCondition = 4;
//         imageSrc = require("../../assets/img/conditions/moderate.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Moderate" />`
//     } else if (currentRating >= CONDITION.MAINTAINED_MIN && currentRating <= CONDITION.MAINTAINED_MAX) {
//         color = '#74D170';
//         actualCondition = 5;
//         imageSrc = require("../../assets/img/conditions/maintained.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Maintained" />`
//     } else if (currentRating >= CONDITION.PARTIAL_REMODEL_MIN && currentRating <= CONDITION.PARTIAL_REMODEL_MAX) {
//         color = '#50C9D6';
//         actualCondition = 6;
//         imageSrc = require("../../assets/img/conditions/partial-remodel.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Partially Remodeled"  />`
//     } else if (currentRating >= CONDITION.FULLY_REMODEL_MIN && currentRating <= CONDITION.FULLY_REMODEL_MAX) {
//         color = '#3D3DDF';
//         actualCondition = 7;
//         imageSrc = require("../../assets/img/conditions/full-remodel.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Fully Remodeled" />`
//     } else if (currentRating >= CONDITION.NEW_BUILT_MIN && currentRating <= CONDITION.NEW_BUILT_MAX) {
//         color = '#AB40D7';
//         actualCondition = 8;
//         imageSrc = require("../../assets/img/conditions/new-build-purple.png").default;
//         imageUrl = `<img src="${imageSrc}" alt="Newly built" />`;
//     }
//     return { "condition": actualCondition, "color": color, imageSrc, imageUrl }
// };

export const removeDuplicatesByFields = function (arr, fields) {
    const uniqueMap = {};
    const uniqueArray = arr.reduce((result, current) => {
        const key = fields.map(field => current[field]).join('-'); // Create a unique key
        if (!uniqueMap[key]) {
            uniqueMap[key] = true;
            result.push(current);
        }
        return result;
    }, []);
    return uniqueArray;
}

export const hasDuplicate = function (array, newItem, propertiesToCheck, indexToSkip) {
    return array
        .filter((_, index) => index !== indexToSkip) // Exclude the item at the specified index
        .some(existingItem => {
            return propertiesToCheck.every(property => existingItem[property] === newItem[property]);
        });
}

export const createLabelFromKey = function (string) {
    let label = string.replace("_", " ");
    label = label.replace("_", " ");
    label = label.replace(/\b\w/g, x => x.toUpperCase())
    return label;
};

export const roundToTenth = function (decimalNumber) {
    const roundedNumber = Math.floor(decimalNumber * 10) / 10;
    return roundedNumber.toFixed(2);
}

export const OpenURLInNewTab = async (address, companyName) => {
    try {
        const splittedAddress = address.split(",");
        let preparePayload, found;
        if (splittedAddress.length === 3 || splittedAddress.length === 4) {
            preparePayload = {
                address: splittedAddress[0].trim(),
                city: splittedAddress[1].trim(),
                state: splittedAddress[2].trim().split(" ")[0],
                postal_code: splittedAddress[2].trim().split(" ")[1]
            };
        } else {
            preparePayload = {
                address: splittedAddress[0].trim(),
                city: splittedAddress[1].trim(),
                state: splittedAddress[2].trim(),
                postal_code: splittedAddress[3].trim()
            };
        }
        const url = process.env.REACT_APP_API_ENDPOINT + "public/search-property-on-google";
        const response = await axios.post(url, preparePayload);
        let openTabURL = '';
        if (companyName === 'redfin') {
            openTabURL = response.data?.data?.redfin;
        } else {
            openTabURL = response.data?.data?.realtor;
        }
        if (openTabURL === "https://www.realtor.com/" || openTabURL === "https://www.redfin.com/") {
            found = false
        } else found = true
        return { status: 200, found, url: openTabURL, message: `Opening ${companyName}.` }
    } catch (error) {
        console.error("Error occurred while searching property on the internet:", error);
        throw error;
    }
};

export const formatSentenceCae = function (value) {
    // Replace underscores with spaces
    let formattedCase = value.replace(/_/g, ' ');

    // Capitalize the first letter of each word
    formattedCase = formattedCase.replace(/\b\w/g, match => match.toUpperCase());

    return formattedCase;
}

export const convertLotSize = function (lotSize) {
    if (lotSize > 0) {
        lotSize = lotSize / 43560;
        lotSize = parseFloat(lotSize, 2);
    }
    return lotSize;
}
