import axios from 'axios';

let BaseUrl = process.env.REACT_APP_API_ENDPOINT;

export function PostData(api, data) {
    let userData = localStorage.getItem("userData") ? JSON.parse(localStorage.getItem("userData")) : "";

    return new Promise((resolve, reject) => {
        console.log("api",api);
        let headers = api==="users/admin-login/"?{}:{
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userData.token}`
        };
        axios({
            url: BaseUrl + api,
            method: 'post',
            data: data,
            headers: headers
        }).then(response => {
            resolve(response);
        }).catch(errors => {
            reject(errors);
            if (errors && errors.response && errors.response.status === 403) {
                window.location = "/auth/login";
            }
        })
    });
}

export function PostDataFullUrl(api, data, headers = {}) {
    return new Promise((resolve, reject) => {

        axios({
            url: api,
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                ...headers
            }
        }).then(response => {
            resolve(response);
        }).catch(errors => {
            reject(errors);
        })

    });

}