import React, { Suspense, useState, useEffect, useRef } from "react";
import { useLocation, Route, Switch, useHistory, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import AdminFooter from "../components/Footers/AdminFooter.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import routes from "../routes.js";
import { loggedInUser } from "../components/Common/Helpers.js";

const Admin = (props) => {
  // const spinnerCurrentStat = useSelector((state) => state.showHideSpinner.currentStat);
  const mainContent = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const userData = loggedInUser();
  const [isExpandSideBarToggle, setToggle] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  useEffect(() => {
    if (!userData || Object.keys(userData).length === 0) {
      history.push("/auth/login");
    } 
    // else {
    //   // history.push('/admin/dashboard');
    // }
  }, [history, userData]);

  const getRoutes = (routes) => {
    let navMenu = [];
    routes.forEach(menu => {     
      navMenu.push(menu);
      if (menu.children) {
        menu.children.forEach(subMenu => {
          navMenu.push(subMenu);
        });
      }
    });

    return navMenu.map((prop, key) => {
      if (prop.layout === "/admin" && prop.is_visible) {
        // console.log("Routes added",prop.path);
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (path.includes(routes[i].layout + routes[i].path)) {
        return routes[i].name;
      }
      if (routes[i].children) {
        for (let j = 0; j < routes[i].children.length; j++) {
          if (path.includes(routes[i].children[j].layout + routes[i].children[j].path)) {
            return routes[i].children[j].name;
          }
        }
      }
    }
    return "";
  };

  const handleToggleSidebar = (status) => {
    setToggle(prevState => status === prevState ? !status : status);
  };

  const navToggle = (status) => {
    setToggle(status);
  };  
  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        screenNavType={isExpandSideBarToggle ? "full-screen-nav" : ""}
        handleNavToggle={navToggle}
        logo={{
          innerLink: "/admin/dashbaord",
          imgSrc: isExpandSideBarToggle
            ? require("../assets/img/brand/DocExtractor_black.png").default
            : require("../assets/img/brand/DocExtractor_black.png").default,
          imgAlt: "...",
        }}
      />

      <div className={isExpandSideBarToggle ? "main-content full-screen" : "main-content"} ref={mainContent}>
        {/* <Spinner isShow={spinnerCurrentStat} /> */}
        <AdminNavbar
          {...props}
          isOpen={handleToggleSidebar}
          onExpandToggle={isExpandSideBarToggle}
          brandText={getBrandText(props.location.pathname)}
        />
        <Suspense fallback={null}>
          <Switch>
            {getRoutes(routes)}
            <Redirect exact from="*" to="/auth/login" />
          </Switch>
        </Suspense>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
