import React, { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { PostData } from "../../services/PostData";
import { GetData } from "../../services/GetData";
import Spinner from "../../components/Common/Spinner";
import { Button, Card, CardBody, FormGroup, InputGroupAddon, InputGroupText, InputGroup, Col } from "reactstrap";
import { encryptData, loggedInUser } from "../../components/Common/Helpers.js";

const Login = (props) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isProcess, setIsProcess] = useState(false);
  const [lockUnlock, setLockUnlock] = useState("fa fa-lock");
  const [menu, setMenu] = useState("/dashboard");

  useEffect(() => {
    // Handle URL parameters
    // const param = props.location.search;
    // const paramString = new URLSearchParams(param);

    // if (param && paramString.get('token')) {
    //   let token = paramString.get('token').replace(/ /g, '+');
    //   let data = { string: token };
    //   setIsProcess(true);
    // }
  }, [props.location.search]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    if (name === 'username') {
      setUsername(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  const handleLogin = ()  => {
    setLockUnlock("fa fa-lock-open");
    setIsProcess(true);
    let data = { email: username, password };

    PostData("user/admin-login/", data).then(result => {
      let responseJson = result.data;
      if (responseJson.token) {
        localStorage.setItem("userData", JSON.stringify(responseJson));
        localStorage.setItem("userProfile", JSON.stringify(responseJson));
        // GetData('user/profile').then(res=>{
        // localStorage.setItem("userProfile", JSON.stringify(res.user_data));
        // })
        setIsProcess(false);
        props.history.push('/admin' + menu);
        toast.success("Login successfully");
      } else {
        toast.error(responseJson.message);
        setIsProcess(false);
        setLockUnlock("fa fa-lock");
      }
    }).catch(() => {
      setIsProcess(false);
    });
  };

  return (
    <Col lg="5" md="7">
      {/* Loader Spinner */}
      <Spinner isShow={isProcess} />

      <Card className="bg-secondary shadow border-0">
        <CardBody className="px-lg-5 py-lg-5">
          <div className="text-center mb-4">
            <b>Login to your account</b>
          </div>
          <AvForm onValidSubmit={handleLogin} >
            <AvGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-envelope" />
                  </InputGroupText>
                </InputGroupAddon>
                <AvInput 
                  name="username"
                  value={username}
                  placeholder="Email"
                  type="email"
                  autoComplete="new-email"
                  onChange={handleInput}
                  required
                />
                <AvFeedback>This field is invalid</AvFeedback>
              </InputGroup>
            </AvGroup>
            <AvGroup>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className={lockUnlock} />
                  </InputGroupText>
                </InputGroupAddon>
                <AvInput 
                  name="password"
                  value={password}
                  placeholder="Password"
                  type="password"
                  autoComplete="new-password"
                  onChange={handleInput}
                  required
                />
                <AvFeedback>This field is invalid</AvFeedback>
              </InputGroup>
            </AvGroup>
            <div className="text-center">
              <FormGroup>
                <Button color="info">
                  Sign in
                </Button>
              </FormGroup>
            </div>
          </AvForm>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Login;
