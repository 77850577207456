import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { PostData } from "../../services/PostData";
import { toast } from "react-toastify";
import Spinner from "../../components/Common/Spinner.js";
import { loggedInUser } from "../../components/Common/Helpers.js";

const AdminNavbar = (props) => {
  const [isLogout, setIsLogout] = useState(false);
  const [userData, setUserData] = useState(loggedInUser() || "");
  const [isExpandSidebar, setIsExpandSidebar] = useState(false);

  useEffect(() => {
    if (!userData) {
      // Redirect to login if userData is not set
      return <Redirect to="/auth/login" />;
    }
  }, [userData]);

  useEffect(() => {
    // Component did mount logic
    if (process.env.REACT_APP_SERVER_ENVIRONMENT === "production") {
      // Any other actions to perform on mount
    }
    // Cleanup function if needed
    return () => {
      // Component will unmount logic
    };
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    setIsLogout(true);
    // localStorage.removeItem("userData");
    // localStorage.removeItem("OCT"); // Other client token
    // setIsLogout(false);
    // props.history.push("/auth/login");
    // console.log("userData.token",userData.token);
    PostData("users/logout/", { })
      .then((result) => {
        let responseJson = result.data;
          localStorage.removeItem("userData");
          localStorage.removeItem("OCT"); // Other client token
          setIsLogout(false);
          props.history.push("/auth/login");
          toast(responseJson.message);
          setIsLogout(false);
        }
      )
      .catch((errors) => {
        toast(errors);
        setIsLogout(false);
      });
  };

  const handleToggleSidebar = (e) => {
    e.preventDefault();
    const newStatus = !isExpandSidebar;
    setIsExpandSidebar(newStatus);
    if (props.isOpen) {
      props.isOpen(newStatus);
    }
  };

  if (!userData) {
    return <Redirect to="/auth/login" />;
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Spinner isShow={isLogout} />
        <Container fluid>
          <Link to="#" onClick={handleToggleSidebar}>
            <span className="menu-nav-icon">
              <i className={isExpandSidebar ? "fas fa-angle-double-right" : "fas fa-angle-double-left"}></i>
            </span>
          </Link>
          <span className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {props.brandText}
          </span>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/avatar.png").default}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {userData.first_name || userData.email  ? `Welcome ${userData.first_name ?userData.first_name  : userData.email}` : ""}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>               
                <DropdownItem href="#" onClick={handleLogout}>
                  <i className="ni ni-button-power color-red" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
