/*
  ### Example:

  Import : import DateFormat from "components/Common/DateFormat";

  ### Component:

   <DateFormat fieldName={fieldName} format={format} row={row} customToolTip={customToolTip} hoverFormat={hoverFormat} />
*/

import moment from 'moment-timezone';
import { UncontrolledTooltip } from 'reactstrap';

moment.tz.setDefault("Asia/Kolkata");

const DateFormat = ({ fieldName, row, format = "MMM D, h:mm a", customToolTip = "", hoverFormat = "MMM D, YY h:mm a" }) => {

  let rowId = `${fieldName}_${row.id}`;
  let value = row[fieldName];

  if (fieldName && value) {
    return (
      <>
        <span id={rowId}>{value ? moment(value).format(format) : '-'}</span>
        <UncontrolledTooltip delay={0} placement="auto" target={rowId}>{customToolTip ? customToolTip + " - " : ""} {value ? moment(value).format(hoverFormat) : '-'}</UncontrolledTooltip>
      </>

    );
  } else {
    return "-";
  }
}

export default DateFormat;