import React from "react";
import { Col } from "reactstrap";
const Footer = () => {
  return (
    <footer className="footer">
      <Col className="align-items-center justify-content-xl-between">
        <div className="copyright text-center text-muted">
          © Copyright 2019 - {new Date().getFullYear()}{" "}

          EasyMonk - All Rights Reserved
        </div>
      </Col>
    </footer>
  );
};

export default Footer;