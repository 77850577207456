import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import "./assets//plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/scss/argon-dashboard-react.scss";
import 'react-toastify/dist/ReactToastify.css';

import AdminLayout from "./layout/Admin.js";
import AuthLayout from "./layout/Auth";
// import ClientLayout from "layouts/Client.js";
// import PublicLayout from "layouts/Public.js";

// import { Provider } from "react-redux";

// import store from "./redux/store";

ReactDOM.render(
  <BrowserRouter>   
    <ToastContainer />
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <AdminLayout {...props} />} />       
      <Redirect from="/" to="/auth/login" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);